<template>
  <div class="SelectColor">
    <v-row>
    <v-col cols="12">
        <v-select :items="colors" label="Cor" hint="Selecione uma Cor" v-model="value" @change="$emit('input', $event)"></v-select>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "SelectColor",
  props:['value'],
  data: () => {
    return {
      color: "",
      colors: ["","red", "orange", "yellow", "green", "blue", "purple"],
    };
  },
};
</script>

<style>
</style>