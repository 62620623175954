<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="$emit('close')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Rotas App</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="$emit('close')"> Fechar </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card>
          <v-card-title>
            {{ route.id ? "Editar" : "Cadastrar" }} Rota
            <v-spacer></v-spacer>
            <v-btn color="success" @click="showForm = !showForm">
              {{ showForm ? "Esconder" : "Mostrar" }} Formulario
            </v-btn>
          </v-card-title>
          <v-card-text v-show="showForm">
            <v-row>
              <v-col>
                <v-text-field
                  v-model="route.name"
                  label="Nome da Rota"
                  hint="Digite o nome da rota"
                  required
                  persistent-hint
                  outlined
                  :counter="255"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="route.path"
                  label="Path da Rota"
                  hint="Digite o path da rota"
                  required
                  persistent-hint
                  outlined
                  :counter="255"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-textarea
                  v-model="route.description"
                  rows="1"
                  label="Descrição"
                  hint="Digite uma descrição se necessario"
                  :counter="255"
                  outlined
                  persistent-hint
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-textarea
                  v-model="json"
                  rows="3"
                  label="Parametros da rota"
                  hint="Digite uma Parametros da rota, deve ser um json valido"
                  outlined
                  persistent-hint
                  :color="validParameters ? 'green' : 'red'"
                  @input="jsonValidate"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-spacer></v-spacer>
              <v-btn depressed color="warning" class="mr-2" @click="cancel">
                Cancelar
              </v-btn>
              <v-btn depressed color="success" @click="save">
                {{ route.id ? "Editar" : "Cadastrar" }} Rota
              </v-btn>
            </v-row>
          </v-card-text>
        </v-card>
        <v-simple-table>
          <thead>
            <tr>
              <th class="text-left">Name</th>
              <th class="text-left">Path</th>
              <th class="text-left">Descrição</th>
              <th class="text-left">Parametros</th>
              <th class="text-left">Ações</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in appRoutes" :key="item.name">
              <td>{{ item.name }}</td>
              <td>{{ item.path }}</td>
              <td>{{ item.description }}</td>
              <td>{{ item.parameters }}</td>
              <td>
                <v-icon small class="mr-2" @click="editar(item)"
                  >mdi-pencil</v-icon
                >
                <v-icon small @click="deletar(item)"> mdi-delete </v-icon>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card>
      <v-snackbar v-model="snackbar" :timeout="2000">
        {{ text }}
      </v-snackbar>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: ["dialog", "appRoutes"],
  data() {
    return {
      notifications: false,
      showForm: false,
      widgets: false,
      route: {},
      json: "",
      validParameters: false,
      snackbar: false,
      text: "",
    };
  },
  methods: {
    editar(item) {
      this.route = item;
      this.showForm = true;
      this.json = JSON.stringify(item.parameters);
      this.jsonValidate(this.json);
    },
    deletar(item) {
      let del = confirm("Tem Certeza?\nEsta Operção é Irreversivel");
      if (del) {
        this.$http.delete(`/approutes/${item.id}`).then((resp) => {
          if (resp.status == 200) {
            (this.snackbar = true), (this.text = "Deletado com Sucesso");
            this.$emit("updateRoutes");
          }
        });
      }
    },
    save() {
      const metodo = this.route.id ? "put" : "post";
      const finalUrl = this.route.id ? `/${this.route.id}` : "";
      this.$http[metodo](`/approutes${finalUrl}`, this.route).then((resp) => {
        if (resp.status == 200) {
          (this.snackbar = true), (this.text = "Salvo com Sucesso");
          this.$emit("updateRoutes");
        }
      });
    },
    cancel() {
      this.route = {};
      this.showForm = false;
      this.json = "";
    },
    jsonValidate(data) {
      try {
        this.route.parameters = JSON.parse(data);
        this.validParameters = true;
        this.json = data;
      } catch (error) {
        this.validParameters = false;
      }
    },
  },
};
</script>