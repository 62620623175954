<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark v-bind="attrs" v-on="on" @click="inicia">
          Abrir Configurador da Rota
        </v-btn>
      </template>
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="dialog = false">
            <v-icon @click="dialog = false">mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Configurador da Rota</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
              <v-btn dark text @click="limpar"> Limpar </v-btn>
            <v-btn dark text @click="save"> Salvar </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        Configuration: {{ configuration }}
        <br />
        New Config : {{ text }}
        <v-simple-table fixed-header>
          <thead>
            <tr>
              <th class="text-center">Atributo</th>
              <th class="text-center">Configuração</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in getObjects(configuration)" :key="index">
              <td>
                {{ item }}
              </td>
              <td>
                <v-simple-table fixed-header>
                  <tbody>
                    <tr
                      v-for="(subitem, sudiIdex) in getObjectData(
                        configuration,
                        item
                      )"
                      :key="sudiIdex"
                    >
                      <td>
                        {{ subitem.prop }}
                      </td>
                      <td>
                        <v-checkbox
                          v-if="subitem.type == Boolean"
                          @change="setItem($event, item, subitem.prop)"
                          v-model="temp[`${item}_${subitem.prop}`]"
                        ></v-checkbox>
                        <v-text-field
                          v-if="subitem.type == String"
                          @input="setItem($event, item, subitem.prop)"
                          v-model="temp[`${item}_${subitem.prop}`]"
                          :color="validateField(item, subitem.prop)"
                        ></v-text-field>
                        <v-text-field
                          v-if="subitem.type == Number"
                          type="number"
                          @input="
                            setItem(parseFloat($event) || 0, item, subitem.prop)
                          "
                          v-model="temp[`${item}_${subitem.prop}`]"
                          :color="validateField(item, subitem.prop)"
                        ></v-text-field>
                        <v-text-field
                          v-if="subitem.type == Array || subitem.type == Object"
                          @input="setItem($event, item, subitem.prop)"
                          v-model="temp[`${item}_${subitem.prop}`]"
                          :color="validateField(item, subitem.prop)"
                        ></v-text-field>
                        <v-checkbox
                          v-if='subitem.type == "Boolean"'
                          @change="setItem($event, item, subitem.prop)"
                          v-model="temp[`${item}_${subitem.prop}`]"
                        ></v-checkbox>
                        <v-text-field
                          v-if="subitem.type == 'String'"
                          @input="setItem($event, item, subitem.prop)"
                          v-model="temp[`${item}_${subitem.prop}`]"
                          :color="validateField(item, subitem.prop)"
                        ></v-text-field>
                        <v-text-field
                          v-if="subitem.type == 'Number'"
                          type="number"
                          @input="
                            setItem(parseFloat($event) || 0, item, subitem.prop)
                          "
                          v-model="temp[`${item}_${subitem.prop}`]"
                          :color="validateField(item, subitem.prop)"
                        ></v-text-field>
                        <v-text-field
                          v-if="subitem.type == 'Array' || subitem.type == 'Object'"
                          @input="setItem($event, item, subitem.prop)"
                          v-model="temp[`${item}_${subitem.prop}`]"
                          :color="validateField(item, subitem.prop)"
                        ></v-text-field>
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: ["configuration", "parametros"],
  data() {
    return {
      dialog: false,
      newConfig: {},
      text: "",
      temp: {},
    };
  },
  methods: {
    getObjects(item) {
      let objs = [];
      for (let prop in item) {
        objs.push(prop);
      }
      return objs;
    },
    getObjectData(item, sub) {
      let objs = [];
      for (let prop in item[sub]) {
        objs.push({ prop, type: item[sub][prop] });
      }
      return objs;
    },
    setItem(data, item, subitem) {
      // se o objeto não existe adiciono
      if (this.newConfig[item] == undefined) {
        this.newConfig[item] = {};
      }
      //   se o subobjeto não exite adiciono
      if (this.newConfig[item][subitem] == undefined) {
        this.newConfig[item][subitem] = data;
      }
      this.newConfig[item][subitem] = data;
      this.text = JSON.stringify(this.newConfig);
    },
    validateField(item, subitem) {
      if (this.newConfig[item] == undefined) {
        return "red";
      }
      if (this.configuration[item][subitem] == Number) {
        if (typeof this.newConfig[item][subitem] == "number") {
          return "green";
        }
        return "red";
      }
      if (this.configuration[item][subitem] == String) {
        if (typeof this.newConfig[item][subitem] == "string") {
          return "green";
        }
        return "red";
      }
      if (this.configuration[item][subitem] == Array) {
        try {
          let arr = JSON.parse(this.newConfig[item][subitem]);
          return Array.isArray(arr) ? "green" : "red";
        } catch (error) {
          return "red";
        }
      }
      if (this.configuration[item][subitem] == Object) {
        try {
          JSON.parse(this.newConfig[item][subitem]);
          return "green";
        } catch (error) {
          return "red";
        }
      }
      if (this.configuration[item][subitem] == "Number") {
        if (typeof this.newConfig[item][subitem] == "number") {
          return "green";
        }
        return "red";
      }
      if (this.configuration[item][subitem] == "String") {
        if (typeof this.newConfig[item][subitem] == "string") {
          return "green";
        }
        return "red";
      }
      if (this.configuration[item][subitem] == "Array") {
        try {
          let arr = JSON.parse(this.newConfig[item][subitem]);
          return Array.isArray(arr) ? "green" : "red";
        } catch (error) {
          return "red";
        }
      }
      if (this.configuration[item][subitem] == "Object") {
        try {
          JSON.parse(this.newConfig[item][subitem]);
          return "green";
        } catch (error) {
          return "red";
        }
      }
    },
    save() {
      this.$emit("newConfig", this.newConfig);
      this.dialog = false;
    },
    limpar() {
      this.newConfig = {};
      this.temp = {};
      this.text = JSON.stringify(this.newConfig);
    },
    inicia() {
      // limpo o antigo se houver
      for (let prop1 in this.temp) {
        this.temp[prop1] = null;
      }
      // remontando o dados nos inputs
      for (let prop in this.parametros) {
        for (let prop2 in this.parametros[prop]) {
          this.temp[`${prop}_${prop2}`] = this.parametros[prop][prop2];
          this.setItem(this.parametros[prop][prop2], prop, prop2);
        }
      }
    },
  },
};
</script>

<style>
</style>