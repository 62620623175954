<template>
  <div class="selectSize">
    <v-row>
    <v-col cols="12">
        <v-select :items="sizes" label="Tamanho" hint="Selecione o Tamanho" v-model="value" @change="$emit('input', $event)"></v-select>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "selectSize",
  props:['value'],
  data: () => {
    return {
      size: "medium",
      sizes: ["x-small", "small", "dense", "medium", "large", "x-large"],
    };
  },
};
</script>

<style>
</style>